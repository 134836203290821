import styled from "styled-components";


export const H2 = styled.h2`
display: flex;
color: red;
justify-content: center;
margin-top: 50px;
margin-bottom: 50px;
font-family: 'Saira', sans-serif;
color: #1E3379;
font-size: 32px;
`

export const P = styled.h3`
display:flex;
justify-content: center;
width: 50%;
font-family: 'Saira', sans-serif;
color: #1E3379;
font-size: 20px;
text-align: center;
margin:auto;
margin-top: 40px;
margin-bottom: 40px;
font-weight: normal;

`
export const H1 = styled.h1`
color: #1E3379;
margin-top: 20px;
align-items: center;
font-family: 'Saira', sans-serif;
display: flex;
justify-content: center;
text-align: center;
`;
