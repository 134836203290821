import styled from "styled-components";



export const H1 = styled.h1`
color: #1E3379;
margin-top: 20px;
align-items: center;
font-family: 'Saira', sans-serif;
display: flex;
justify-content: center;
text-align: center;
`;


export const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
font-family: 'Saira', sans-serif;
margin-top: 20px;
`;


export const ContainerPoppy = styled.div`
font-family: 'Saira', sans-serif;
display: flex;
width: 80%;
margin-bottom: 40px;
flex-direction: column;
font-size: 16px;
font-weight: 400;

`;

export const H2 = styled.h2`
display: flex;
color: red;
justify-content: center;
margin-top: 50px;

font-family: 'Saira', sans-serif;
color: #1E3379;
font-size: 32px;
`



