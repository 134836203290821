import react from 'react';

import {H1,Container,ContainerPoppy, H2} from './style'
import Sobre from '../../Sobre';
import Vantagens from '../../Vantagens';
import Galeria from '../../Galeria';

const Poppy = ()=>{

    return(
        <>
        <Container>
      <H1>Poppy 3d Maquete Eletrônica 3d</H1>
        <ContainerPoppy>
       <H2>E aí, galera! </H2>  
      
       <H2>Na Poppy 3D, a gente manda ver nas imagens 3D para eventos! 🌍✨</H2> 
        <br></br> Desbravamos os melhores espaços de eventos pelo Brasil e além-fronteiras, tipo Alemanha, EUA, Portugal e França. <br></br> Estamos nessa há mais de 20 anos, sempre se aprimorando e deixando os clientes de queixo caído!

<br></br><br></br>A Poppy 3D é expert em criar maquetes eletrônicas 3D top para agências de publicidade, eventos irados, cenografia, escritórios de arquitetura e engenharia, e até maquetes 3D descoladas para Stands. Demais, né? <br></br>
<br></br>
No frenesi do mundo dos eventos, a gente sabe que o segredo é qualidade e rapidez. E olha só, temos uma biblioteca gigante com os melhores espaços, móveis e paisagismo que você pode imaginar, tudo para dar aquele up no seu projeto com maquetes eletrônicas de alta qualidade.

<br></br><br></br>Na Poppy 3D, cada projeto é uma vibe única. Tamo aqui pra superar todas as expectativas e transformar suas ideias em pura realidade visual. Bora deixar seu evento inesquecível com maquetes eletrônicas que são puro sucesso! 💥🚀
      
        </ContainerPoppy>
 

        <Vantagens/>
      <Sobre/>
        <Galeria/>
        </Container>
        </>
    )
}

export default Poppy