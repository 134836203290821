import React from "react";
import Areas from "../../Areas";
import Contact from "../../Contact";
import {H2,P, H1} from './style'
import Galeria from "../../Galeria";


const Servicos = ()=>{
    return(
        <>
            <H1>Renderização de imagens 3d para Cenografia e Arquitetura</H1>
          <Areas/>  
          <Galeria/>
          <H2>Fique a vontade para entrar em contato</H2>
          <P>A Poppy 3d cria imagens 3d para cenografia e também presta serviços de modelagem 3d para arquitetura.</P>
<P>Navegue por nosso site e confira tudo sobre modelagem 3d para arquitetura e eventos em geral</P>
<Contact/>

        </>
    )
}
export default Servicos;