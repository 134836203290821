import styled from "styled-components";



export const H2 = styled.h2`
display: flex;
color: red;
justify-content: center;
align-items: center;
text-align: center;
margin-top: 50px;
margin-bottom: 50px;
font-family: 'Saira', sans-serif;
color: #1E3379;
font-size: 32px;
`