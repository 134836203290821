import styled from "styled-components";

import Foto1 from '../../assets/imagem3dPostIt.jpg';

export const Container = styled.div`

width: 100%;





/* 
background-image: url(${Foto1});
background-size: cover; */
`;

export const Img = styled.img`
width: 100vw;
height: 520px;


object-fit: cover;
`; 