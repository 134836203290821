import React from "react";
import {ContainerText,H2} from './style'

const Sobre = ()=>{
    return(
        <>
          <ContainerText>
            <H2> Imagens 3d para eventos, stands e arquitetura.</H2> 
            <div>
    <p>E aí, galera! A Poppy 3D está na área há mais de 20 anos, dominando o universo das imagens 3D para eventos, seja aqui no Brasil ou em destinos internacionais como Alemanha, EUA, Portugal e França. 😎✈️</p>
</div>

<div>
    <p>É uma jornada de experiência, aprimoramento e satisfação total dos nossos clientes. 
      
      </p> <H2>Afinal, somos a Poppy 3D, e aqui, cada projeto é uma obra-prima!</H2>
</div>

<div>
    <p>Nosso rolê é especializado em criar maquetes eletrônicas 3D para agências de publicidade, eventos que são pura vibe, cenografia que faz a diferença, e até maquetes iradas para escritórios de arquitetura e engenharia, incluindo aquele stand que vai ser o destaque do rolê!</p>
</div>

<div>
    <p>Nós sacamos que no mundo dos eventos é tudo na base da pressa e sem abrir mão da qualidade. Por isso, pode contar com a gente! Temos uma biblioteca gigante com os lugares mais tops, móveis e paisagismo que são hit nos eventos, tudo para deixar seu projeto ainda mais incrível com maquetes eletrônicas em altíssima qualidade.</p>
</div>


    <H2>Na Poppy 3D, não fazemos só maquetes, a gente faz acontecer! Queremos transformar cada ideia sua em um evento ou projeto que seja digno de likes. Vem com a gente, porque aqui é sucesso garantido! 🚀🌟</H2>

           
            </ContainerText>
         

        </>
    )
}

export default Sobre