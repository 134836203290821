import react from 'react';
import {FaCheck} from 'react-icons/fa'

import {BackColor,
    ContainerBacks,
    ContainerFull,
    ContainerImage,
    Image, 
    H2,
    H3,
        H4,
    ContainerLeftInner,
    ContainerIcone,
    ContainerLeft,
    Icone} from './style'

import Foto from '../../assets/UOL-gamearea0030000.webp';
import Buttons from '../Buttons';
const Vantagens = ()=>{
    return (
        <>
       <ContainerFull>
       <H2>

Na Poppy 3D, transformamos suas visões em experiências visuais incríveis. <br></br>Bora fazer os outros eventos morrerem de inveja dos seus? <br></br>Vem com a gente! 🎉🔥
</H2>
        <ContainerLeft>
           
            <H3>E aí, curioso? <br></br> Dá uma olhada nas vantagens que só a Poppy 3D traz para o seu rolê ou projeto! 🌟</H3>
            <ContainerLeftInner>
                <Icone><FaCheck/></Icone>
                <div><strong>Visualização realista do projeto</strong><br></br>
                    Nossas maquetes são tão reais que você vai sentir que já está no evento.  <br></br> É tipo dar um preview antes do show começar!
                </div>
                
            </ContainerLeftInner>
            <ContainerLeftInner>
                <Icone><FaCheck/></Icone>
                <div><strong>Problemas? Identificamos Antes!</strong>
                <br></br>Mano, a gente pega os pepinos logo de cara, antes mesmo de virarem problema. <br></br>Prevenir é melhor que remediar, né?
                </div>
            </ContainerLeftInner>
            <ContainerLeftInner>
                <Icone><FaCheck/></Icone>
                <div>
                    <strong>Conversa com Cliente é Mamão</strong>
                   <br></br> Aqui, a comunicação é sem enrolação. <br></br>As maquetes falam por si e deixam todo mundo no mesmo page. <br></br>Cliente feliz, projeto feliz!
                    </div>
            </ContainerLeftInner>
            <ContainerLeftInner>
                <Icone><FaCheck/></Icone>
                <div><strong>Apoio ao Marketing é Estilo Hollywood</strong><br></br>
                Imagina só poder mostrar o seu evento antes dele acontecer? 
                <br></br>É isso que a gente faz! Marketing e vendas no estilo futurista!</div>
            </ContainerLeftInner>
            <ContainerLeftInner>
                <Icone><FaCheck/></Icone>
                <div> <strong>Treinamento e Educação com Estilo</strong>

                    <br>
                    </br>Não é só bonito, é educativo também. <br></br>Usamos as maquetes para treinar e educar, tipo um mestre jedi da arquitetura e cenografia.
                </div>
            </ContainerLeftInner>
            <ContainerLeftInner>
                <Icone><FaCheck/></Icone>
                <div><strong>Sustentabilidade é com a Gente:</strong>
                <br></br> É sustentabilidade na veia! <br></br> Com as maquetes, evitamos desperdícios, dando uma força para o meio ambiente.
                </div>
            </ContainerLeftInner>
            <ContainerLeftInner>
                <Icone><FaCheck/></Icone>
                <div> <strong>Controle de Custos sem Estresse:</strong>
                <br></br>Quer manter os gastos na linha? As maquetes ajudam a ter o controle total dos custos, sem surpresas no final.
                </div>
            </ContainerLeftInner>
            <ContainerLeftInner>
                <Icone><FaCheck/></Icone>
                <div> <strong>Design Sustentável é Pura Atitude:</strong><br></br>
                Design é nossa praia! <br></br> Testamos ideias sustentáveis para um evento que é puro estilo e consciência ambiental.</div>
            </ContainerLeftInner>
            <ContainerLeftInner>
                <Icone><FaCheck/></Icone>
                <div> <strong>Documentação que Dá Show:
                <br></br></strong>
                Nossas maquetes são como o GPS do seu projeto, te mostrando cada curva e detalhe. <br></br> Documentação é a chave para o sucesso!

                </div>
            </ContainerLeftInner>
                <Buttons title="Faça um orçamento"/>
        </ContainerLeft>
        <ContainerImage>
            <ContainerBacks>
                <Image alt="Imagem 3d do Uol" src={Foto}></Image>
                <BackColor></BackColor>
            </ContainerBacks>
        </ContainerImage>
        <H4>Na Poppy 3D, a gente não faz só imagens, a gente faz mágica. Vem com a gente e deixe seu evento ou projeto na vibe mais top! 🚀🌈</H4>
       </ContainerFull>
        </>
    )
}
export default Vantagens;